import {SET_OFFICES} from "../constants";

const initialState = {
  offices: [],
};

export default function Offices(state = initialState, action) {
  switch (action.type) {
    case SET_OFFICES:
      return {
        ...state,
        offices: action.offices,
      };
    default:
      return state;
  }
}
