import {
  LEAD_RESET_STATE,
  SET_LEAD_DETAILS_LEAD,
  SET_LEAD_MOVES,
  UPDATE_LEAD_MOVE,
  UPDATE_LEAD_MOVE_NOTE,
  UPDATE_LEAD_SELECTED_INVENTORY,
} from "../constants";

const initState = {
  lead: {},
  leadMoves: [],
  leadSelectedInventory: {},
};

export default function Lead(state = initState, action) {
  switch (action.type) {
    case SET_LEAD_DETAILS_LEAD:
      return {
        ...state,
        lead: action.lead,
      };
    case LEAD_RESET_STATE:
      return initState;
    case SET_LEAD_MOVES:
      return {
        ...state,
        leadMoves: action.leadMoves,
      };
    case UPDATE_LEAD_MOVE:
      return {
        ...state,
        leadMoves: state.leadMoves.map((move) =>
          action.leadMove.id === move.id ? action.leadMove : move
        ),
      };
    case UPDATE_LEAD_MOVE_NOTE:
      const {id, note} = action;
      const {leadMoves} = state;
      const updatedMoves = leadMoves.map((move) => {
        if (move.id === id) {
          const existingNote = (move.notes ?? []).find(
            (existingNote) => existingNote.note_type === note.note_type
          );
          if (existingNote) {
            const updatedNotes = move.notes.map((existingNote) =>
              existingNote.note_type === note.note_type ? {...existingNote, ...note} : existingNote
            );
            return {...move, notes: updatedNotes};
          } else {
            return {...move, notes: [...(move.notes ?? []), note]};
          }
        }
        return move;
      });

      return {...state, leadMoves: updatedMoves};
    case UPDATE_LEAD_SELECTED_INVENTORY:
      return {
        ...state,
        leadSelectedInventory: action.leadSelectedInventory,
      };
  }
  return state;
}
