import {
  SET_TEXT_DETAILS,
  SET_TEXT_LIST,
  SET_TEXT_LOADING,
  TEXT_RESET_STATE,
} from "@/redux/constants";

const initState = {
  textList: [],
  textDetails: undefined,
  isTextLoading: false,
};

export default function Text(state = initState, action) {
  switch (action.type) {
    case SET_TEXT_LIST:
      return {
        ...state,
        textList: action.textList,
      };
    case SET_TEXT_DETAILS:
      return {
        ...state,
        textDetails: action.textDetails,
      };
    case SET_TEXT_LOADING:
      return {
        ...state,
        isTextLoading: action.isTextLoading,
      };
    case TEXT_RESET_STATE:
      return initState;
  }
  return state;
}
