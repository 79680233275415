import {useMemo} from "react";
import {createStore, applyMiddleware} from "redux";
import {thunk} from "redux-thunk";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {createLogger} from "redux-logger";
import getReducers from "./reducers/index";

const persistConfig = {
  key: "nextjs",
  storage: storage,
  whitelist: ["ui"],
};

let store;

const exampleInitialState = {};

const persistedReducer = persistReducer(persistConfig, getReducers());

const logger = createLogger({collapsed: true});
const middlewares = process.env.NODE_ENV !== "production" ? [logger, thunk] : [thunk];

function makeStore(initialState = exampleInitialState) {
  return createStore(persistedReducer, initialState, applyMiddleware(...middlewares));
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? makeStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...preloadedState,
      ...store.getState(),
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
