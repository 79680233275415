import {withTranslation, useTranslation, appWithTranslation} from "next-i18next";
export {withTranslation, useTranslation, appWithTranslation};

const i18nextHttpBackend = require("i18next-http-backend/cjs");
export const nextI18NextConfig = {
  i18n: {
    locales: ["en"],
    defaultLocale: "en",
  },
  localePath:
    typeof window === "undefined" ? require("path").resolve("public/locales") : "public/locales",
  partialBundledLanguages: true,
  use: process.browser ? [i18nextHttpBackend] : [],
};

const lockMap = {};

export const idempotentFunction = (key, handler) => {
  return async function (...args) {
    if (lockMap[key]) {
      return;
    }
    lockMap[key] = true;
    try {
      await handler(...args);
    } finally {
      lockMap[key] = false;
    }
  };
};

export const imageUrlToBase64 = async (url) => {
  const response = await fetch(url, {method: "GET", credentials: "include"});
  const blob = await response.blob();
  return new Promise((onSuccess, onError) => {
    try {
      const reader = new FileReader();
      reader.onload = function () {
        onSuccess(this.result);
      };
      reader.readAsDataURL(blob);
    } catch (e) {
      console.log(e, "Error occured reading");
      onError(e);
    }
  });
};

export function formatMinutesAsTravelTime(minutes) {
  const days = Math.floor(minutes / (24 * 60));
  const hours = Math.floor((minutes % (24 * 60)) / 60);
  const mins = minutes % 60;
  const parts = [];
  if (days > 0) {
    parts.push(`${days} day${days > 1 ? "s" : ""}`);
  }
  if (hours > 0) {
    parts.push(`${hours} hour${hours > 1 ? "s" : ""}`);
  }
  if (mins > 0) {
    parts.push(`${mins} min${mins > 1 ? "s" : ""}`);
  }
  return parts.join(" ");
}
