import {
  SET_LEAD_LIST_VIEW,
  SET_LEAD_PAGE_NUMBER,
  SET_LEAD_IS_METRIC,
  SET_LEAD_PACKING,
  SET_BOX_IS_METRIC,
  SET_SALES_PIPELINE_VIEW,
  SET_LAST_VISITED_LEADS,
} from "@/redux/constants";

const initState = {
  leadListView: 0,
  leadPageNumber: 1,
  leadIsMetric: {},
  boxIsMetric: {},
  leadPacking: {},
  salesPipelineView: "grid",
  lastVisitedLeads: {},
};

export default function Text(state = initState, action) {
  switch (action.type) {
    case SET_LEAD_LIST_VIEW:
      return {
        ...state,
        leadListView: action.leadListView,
      };
    case SET_LEAD_PAGE_NUMBER:
      return {
        ...state,
        leadPageNumber: action.leadPageNumber,
      };
    case SET_LEAD_IS_METRIC:
      return {
        ...state,
        leadIsMetric: {
          ...state.leadIsMetric,
          [action.companyId]: action.leadIsMetric,
        },
      };
    case SET_LEAD_PACKING:
      return {
        ...state,
        leadPacking: {
          ...state.leadPacking,
          [action.companyId]: action.leadPacking,
        },
      };
    case SET_BOX_IS_METRIC:
      return {
        ...state,
        boxIsMetric: {
          ...state.boxIsMetric,
          [action.companyId]: action.boxIsMetric,
        },
      };
    case SET_SALES_PIPELINE_VIEW:
      return {
        ...state,
        salesPipelineView: action.salesPipelineView,
      };
    case SET_LAST_VISITED_LEADS:
      return {
        ...state,
        lastVisitedLeads: {
          ...state.lastVisitedLeads,
          [`${action.companyId}_${action.userId}`]: action.lastVisitedLeads,
        },
      };
  }
  return state;
}
