import {SET_REMOTE_CAMERA_STATUS} from "../constants";

const initState = {
  remoteCameraActive: false,
};

export default function Survey(state = initState, action) {
  switch (action.type) {
    case SET_REMOTE_CAMERA_STATUS:
      return {
        ...state,
        remoteCameraActive: action.active,
      };
  }
  return state;
}
