export const SET_USER = "SET_USER";
export const SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY";
export const SET_MEMBERSHIP = "SET_MEMBERSHIP";
export const SET_LEAD_SOURCES = "SET_LEAD_SOURCES";
export const SET_MOVE_TYPES = "SET_MOVE_TYPES";
export const SET_LEAD_STATUSES = "SET_LEAD_STATUSES";
export const SET_LEAD_STAGES = "SET_LEAD_STAGES";
export const SET_TASK_TYPES = "SET_TASK_TYPES";
export const SET_OFFICES = "SET_OFFICES";
export const COMPANY_RESET_STATE = "COMPANY_RESET_STATE";
export const LEAD_RESET_STATE = "LEAD_RESET_STATE";
export const TYPES_RESET_STATE = "TYPES_RESET_STATE";
export const USER_RESET_STATE = "USER_RESET_STATE";
export const CALLS_RESET_STATE = "CALLS_RESET_STATE";
export const SET_PLANS = "SET_PLANS";
export const SET_COMPANY_PLAN = "SET_COMPANY_PLAN";
export const SET_COMPANY_TRIAL = "SET_COMPANY_TRIAL";
export const GET_TARIFFS = "GET_TARIFFS";
export const GET_SELECTED_TARIFF = "GET_SELECTED_TARIFF";
export const SET_SIDEBAR_STATE = "SET_SIDEBAR_STATE";
export const SET_SHOW_RIGHT_SIDEBAR = "SET_SHOW_RIGHT_SIDEBAR";
export const SET_REMOTE_CAMERA_STATUS = "SET_REMOTE_CAMERA_STATUS";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ACTIVE_LEAD_ON_CALL = "SET_ACTIVE_LEAD_ON_CALL";
export const SET_ACTIVE_EMPLOYEE_ON_CALL = "SET_ACTIVE_EMPLOYEE_ON_CALL";
export const SET_CALL_MODAL_MINIMIZED = "SET_CALL_MODAL_MINIMIZED";
export const SET_STAGE_TYPES = "SET_STAGE_TYPES";
export const SET_TEXT_LIST = "SET_TEXT_LIST";
export const SET_TEXT_DETAILS = "SET_TEXT_DETAILS";
export const FETCH_TEXT_LIST = "FETCH_TEXT_LIST";
export const FETCH_TEXT_DETAILS = "FETCH_TEXT_DETAILS";
export const TEXT_RESET_STATE = "TEXT_RESET_STATE";
export const SET_TEXT_LOADING = "SET_TEXT_LOADING";
export const SET_LEAD_TIMELINE = "SET_LEAD_TIMELINE";
export const SET_LEAD_DETAILS_LEAD = "SET_LEAD_DETAILS_LEAD";
export const SET_LEAD_MOVES = "SET_LEAD_MOVES";
export const UPDATE_LEAD_MOVE = "UPDATE_LEAD_MOVE";
export const UPDATE_LEAD_MOVE_NOTE = "UPDATE_LEAD_MOVE_NOTE";
export const UPDATE_LEAD_SELECTED_INVENTORY = "UPDATE_LEAD_SELECTED_INVENTORY";
export const SET_LEAD_LIST_VIEW = "SET_LEAD_LIST_VIEW";
export const SET_LEAD_PAGE_NUMBER = "SET_LEAD_PAGE_NUMBER";
export const SET_LEAD_IS_METRIC = "SET_LEAD_IS_METRIC";
export const SET_LEAD_PACKING = "SET_LEAD_PACKING";
export const SET_BOX_IS_METRIC = "SET_BOX_IS_METRIC";
export const SET_SALES_PIPELINE_VIEW = "SET_SALES_PIPELINE_VIEW";
export const SET_LAST_VISITED_LEADS = "SET_LAST_VISITED_LEADS";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const DELETE_TEMPLATES = "DELETE_TEMPLATES";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const ADD_INTERVAL = "ADD_INTERVAL";
export const DELETE_INTERVAL = "DELETE_INTERVAL";
export const UPDATE_INTERVAL = "UPDATE_INTERVAL";
