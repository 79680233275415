import {LEAD} from "@/constants/lead";
import {
  SET_LEAD_SOURCES,
  SET_MOVE_TYPES,
  SET_LEAD_STAGES,
  SET_TASK_TYPES,
  TYPES_RESET_STATE,
  SET_STAGE_TYPES,
  SET_TEMPLATES,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATES,
  ADD_TEMPLATE,
  ADD_INTERVAL,
  DELETE_INTERVAL,
  UPDATE_INTERVAL,
} from "../constants";

const initialState = {
  leadStatuses: LEAD.STATUSES,
  leadSources: null,
  moveTypes: null,
  leadStages: null,
  taskTypes: null,
  moveSizes: LEAD.MOVE_SIZES,
  stageTypes: [
    {id: 0, name: "Survey scheduled"},
    {id: 1, name: "Survey completed"},
    {id: 2, name: "Quote(s) created"},
    {id: 3, name: "Quote(s) sent"},
  ],
  insuranceTypes: [
    {
      id: 0,
      code: 0,
      label: "Catastrophic liability",
      name: "catastrophic_liability",
    },
    {id: 1, code: 1, label: "Standard", name: "standard"},
    {
      id: 2,
      code: 2,
      label: "Full value protection 1000 deductible",
      name: "full_value_protection_1000",
    },
    {
      id: 3,
      code: 3,
      label: "Full value protection 750 deductible",
      name: "full_value_protection_750",
    },
    {
      id: 4,
      code: 4,
      label: "Full value protection 500 deductible",
      name: "full_value_protection_500",
    },
    {
      id: 5,
      code: 5,
      label: "Full value protection 250 deductible",
      name: "full_value_protection_250",
    },
    {
      id: 6,
      code: 6,
      label: "Full value protection 0 deductible",
      name: "full_value_protection_0",
    },
    // { id: 7, code: 7, name: "Full value protection up to sum" },
  ],
  templates: [],
};

export default function Types(state = initialState, action) {
  switch (action.type) {
    case SET_LEAD_STAGES:
      return {
        ...state,
        leadStages: action.leadStages,
      };
    case SET_LEAD_SOURCES:
      return {
        ...state,
        leadSources: action.leadSources,
      };
    case SET_MOVE_TYPES:
      return {
        ...state,
        moveTypes: action.moveTypes,
      };
    case SET_TASK_TYPES:
      return {
        ...state,
        taskTypes: action.taskTypes,
      };
    case TYPES_RESET_STATE:
      return initialState;
    case SET_STAGE_TYPES:
      return {
        ...state,
        stageTypes: action.stageTypes,
      };
    case SET_TEMPLATES:
      return {
        ...state,
        templates: action.templates,
      };
    case ADD_TEMPLATE:
      return {...state, templates: [...state.templates, action.template]};
    case DELETE_TEMPLATES:
      return {...state, templates: state.templates.filter(({id}) => id !== action.id)};
    case UPDATE_TEMPLATE:
      return {
        ...state,
        templates: state.templates.map((template) =>
          template.id === action.template.id ? action.template : template
        ),
      };
    case ADD_INTERVAL:
      const newTemplates = state.templates.map((template) => {
        if (template.id !== action.interval.template) return template;
        return {
          ...template,
          followups: [...template.followups, action.interval],
        };
      });

      return {
        ...state,
        templates: newTemplates,
      };
    case DELETE_INTERVAL:
      return {
        ...state,
        templates: state.templates.map((template) => ({
          ...template,
          followups: template.followups.filter(({id}) => id !== action.id),
        })),
      };
    case UPDATE_INTERVAL:
      const updatedTemplates = state.templates.map((template) => {
        if (template.id !== action.interval.template) return template;
        return {
          ...template,
          followups: template.followups.map((followup) =>
            followup.id === action.interval.id ? action.interval : followup
          ),
        };
      });
      return {...state, templates: updatedTemplates};
    default:
      return state;
  }
}
