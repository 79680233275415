import {
  COMPANY_RESET_STATE,
  SET_CURRENT_COMPANY,
  SET_MEMBERSHIP,
  SET_COMPANY_PLAN,
  SET_COMPANY_TRIAL,
} from "../constants";

const initState = {
  membership: [],
  current: null,
  currentPlan: null,
  nextPlan: null,
};

export default function Company(state = initState, action) {
  switch (action.type) {
    case SET_CURRENT_COMPANY:
      return {
        ...state,
        current: action.company,
        currentPlan: action.plan,
        nextPlan: action.nextPlan,
      };
    case SET_MEMBERSHIP:
      return {...state, membership: action.membership};
    case SET_COMPANY_PLAN:
      return {
        ...state,
        currentPlan: action.plan,
        nextPlan: action.nextPlan,
        current: {
          ...state.current,
          subscription_active: true,
        },
      };
    case SET_COMPANY_TRIAL:
      return {
        ...state,
        current: {...state.current, trial_start: action.trial},
      };
    case COMPANY_RESET_STATE:
      return initState;
  }
  return state;
}
