import {SET_USER, USER_RESET_STATE} from "../constants";

export default function User(state = null, action) {
  switch (action.type) {
    case SET_USER:
      return action.user;
    case USER_RESET_STATE:
      return null;
    default:
      return state;
  }
}
