import {SET_SIDEBAR_STATE, SET_IS_LOADING, SET_SHOW_RIGHT_SIDEBAR} from "../constants";

const initialState = {
  isExpanded: true,
  isLoading: false,
  showRightSidebar: false,
};

export default function Layout(state = initialState, action) {
  switch (action.type) {
    case SET_SIDEBAR_STATE:
      return {
        ...state,
        isExpanded: action.isExpanded,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case SET_SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.showRightSidebar,
      };
    default:
      return state;
  }
}
