export const LEAD = {
  STATUSES: [
    {id: 1, code: 1, name: "New", color: "#00D7FF"},
    {id: 2, code: 2, name: "Follow-up", color: "#FF7B50"},
    {id: 3, code: 3, name: "Scheduled survey", color: "#00AACA"},
    {id: 4, code: 4, name: "No show", color: "#596BFF"},
    {id: 5, code: 5, name: "Quote sent", color: "#00A4FF"},
    {id: 7, code: 7, name: "Storage", color: "#914EFF"},
    {id: 8, code: 8, name: "Confirmed", color: "#48D7AB"},
    {id: 9, code: 9, name: "Lost", color: "#4A525D"},
    {id: 10, code: 10, name: "Rejected", color: "#E756D9"},
    {id: 11, code: 11, name: "Booked", color: "#23D30C"},
    {id: 12, code: 12, name: "Claim", color: "#A90024"},
    {id: 13, code: 13, name: "Move in progress", color: "#167C09"},
    {id: 6, code: 6, name: "Move completed", color: "#48D7AB"},
    {id: 14, code: 14, name: "Canceled", color: "#48D7AB"},
    {id: 15, code: 15, name: "In-house quote", color: "#48D7AB"},
  ],
  MOVE_SIZES: [
    {id: 0, code: "0", name: "Studio"},
    {id: 1, code: "1", name: "One Room"},
    {id: 2, code: "2", name: "Two Rooms"},
    {id: 3, code: "3", name: "Three Rooms"},
    {id: 4, code: "4", name: "Four Plus Rooms"},
  ],
};
