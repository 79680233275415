import {SET_PLANS} from "../constants";

const initialState = {
  plans: null,
};

export default function Plans(state = initialState, action) {
  switch (action.type) {
    case SET_PLANS:
      return {...state, plans: action.plans};
  }
  return initialState;
}
