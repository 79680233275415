import {
  CALLS_RESET_STATE,
  SET_ACTIVE_LEAD_ON_CALL,
  SET_ACTIVE_EMPLOYEE_ON_CALL,
  SET_CALL_MODAL_MINIMIZED,
} from "@/redux/constants";

const initState = {
  activeLead: null,
  activeEmployee: null,
  isMinimized: false,
};

export default function Calls(state = initState, action) {
  switch (action.type) {
    case SET_ACTIVE_LEAD_ON_CALL:
      return {
        ...state,
        activeLead: action.activeLead,
      };
    case SET_ACTIVE_EMPLOYEE_ON_CALL:
      return {
        ...state,
        activeEmployee: action.activeEmployee,
      };
    case SET_CALL_MODAL_MINIMIZED:
      return {
        ...state,
        isMinimized: action.isMinimized,
      };
    case CALLS_RESET_STATE:
      return initState;
  }
  return state;
}
