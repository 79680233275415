import {combineReducers} from "redux";
import user from "./user";
import company from "./company";
import lead from "./lead";
import types from "./types";
import offices from "./offices";
import plans from "./plans";
import layout from "./layout";
import survey from "./survey";
import calls from "./calls";
import text from "./text";
import ui from "./ui";

export default () =>
  combineReducers({
    user,
    company,
    lead,
    types,
    offices,
    plans,
    survey,
    layout,
    calls,
    text,
    ui,
  });
