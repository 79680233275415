import React, {useEffect} from "react";
import {useRouter} from "next/router";
import NProgress from "nprogress";
import moment from "moment";
import {Provider} from "react-redux";
import {persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";
import {ToastContainer} from "react-toastify";
import {appWithTranslation, nextI18NextConfig} from "@/util/helper";
import {useStore} from "../redux/store";
import getConfig from "next/config";
import {clarity} from "react-microsoft-clarity";

import "react-toastify/dist/ReactToastify.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import "react-quill/dist/quill.snow.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-circular-progressbar/dist/styles.css";
import "nprogress/nprogress.css";
import "./global.css";
import "../components/Dashboard/Schedule/TaskCalendarDisplay/big-calendar.css";

const MyApp = (props) => {
  const {Component, pageProps} = props;
  const router = useRouter();
  const {locale} = router;
  const {
    publicRuntimeConfig: {buildVersion},
  } = getConfig();
  // const pathname = usePathname()
  // const searchParams = useSearchParams()
  // useEffect(() => {
  //   const handleStart = () => {
  //     NProgress.start()
  //   }
  //   const handleStop = () => {
  //     NProgress.done()
  //   }

  //   handleStop()

  //   return () => {
  //     handleStart()
  //   }
  // }, [pathname, searchParams])

  let loadingTimeout;
  router.events?.on("routeChangeStart", (url) => {
    loadingTimeout = setTimeout(() => {
      NProgress.start();
    }, 300);
  });
  router.events?.on("routeChangeComplete", () => {
    clearTimeout(loadingTimeout);
    NProgress.done();
  });
  router.events?.on("routeChangeError", () => {
    clearTimeout(loadingTimeout);
    NProgress.done();
  });

  const checkBuildVersion = async () => {
    const response = await fetch("/build-version/");
    let serverBuildVersion = await response.json();
    serverBuildVersion = serverBuildVersion?.buildVersion;
    if (serverBuildVersion && buildVersion && serverBuildVersion !== buildVersion) {
      console.error(`Detected new build version: ${serverBuildVersion} old is ${buildVersion}`);
      // There's a new version deployed that we need to load
      window.location.reload();
    }
  };
  useEffect(() => {
    if (!clarity.hasStarted()) {
      if (process.env.CLARITY_ID) {
        console.warn("CLARITY_ID is defined. Microsoft Clarity will be initialized.");
        clarity.init(process.env.CLARITY_ID, {debug: false});
      } else {
        console.warn(
          "CLARITY_ID is not defined. Microsoft Clarity will be initialized with default."
        );
        clarity.init("mq5tamprdj", {debug: false});
      }
    }
    let intervalRef = setInterval(() => {
      checkBuildVersion();
    }, 30000);

    return () => {
      clearInterval(intervalRef);
    };
  }, []);

  moment.updateLocale(locale, {
    week: {
      dow: 1,
      doy: 1,
    },
    relativeTime: {
      future: "%s",
      past: "%s",
      s: "1s",
      m: "1m",
      mm: "%dm",
      h: "1h",
      hh: "%dh",
      d: "1d",
      dd: "%dd",
      M: "1m",
      MM: "%dm",
      y: "1y",
      yy: "%dy",
    },
  });

  const store = useStore(pageProps.initialReduxState);
  const persistor = persistStore(store);

  // noinspection HtmlRequiredTitleElement
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Component {...pageProps} />
        </PersistGate>
      </Provider>

      <ToastContainer
        style={{width: "420px"}}
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={true}
        pauseOnHover={true}
      />
    </>
  );
};

// MyApp.getInitialProps = async ({ Component, ctx }) => {
//   let pageProps = {};

//   if (Component && Component.getInitialProps) {
//     pageProps = (await Component.getInitialProps(ctx)) || {};
//   }
//   if (!pageProps.namespacesRequired) {
//     pageProps.namespacesRequired = [];

//     console.warn(
//       `No translation namespace requested in page ${Component.name}`
//     );
//   }

//   return {
//     pageProps,
//   };
// };

export default appWithTranslation(MyApp, nextI18NextConfig);
